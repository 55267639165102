import React from 'react';
import {Theme} from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AppBar, CssBaseline, Toolbar, Typography, Container, Box} from "@mui/material";
import {useEffect} from "react";
import {useMsal} from "@azure/msal-react";

const toolBarHeight = 65;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: "black"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

interface Props {
}

const Index = (props: Props) => {

  const classes = useStyles();
  const {instance} = useMsal();

  useEffect(() => {
    alert('You are not authorized to access this site. You will be pushed to logout now.');
    instance.logout().then(() => window.close());
    // eslint-disable-next-line
  }, [])

  return (
    <Box className={classes.root}>
      <CssBaseline/>
      <AppBar style={{backgroundColor: "#e26124"}} position="fixed" className={classes.appBar}>
        <Toolbar style={{minHeight: toolBarHeight}}>
          <Typography variant="h5" noWrap>
            Xenqu Tests
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content} style={{backgroundColor: 'red'}}>
        <div className={classes.toolbar}/>
        <Container>
          <Box sx={{textAlign: "center"}}>
            <Typography variant={'h3'}>Unauthorized</Typography>
          </Box>
        </Container>
      </main>
    </Box>
  );

}

export default Index;
