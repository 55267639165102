import {Grid, MenuItem, TextField} from "@mui/material";
import {ImageMap, OnFailVarAction} from "../../../core/Enums";
import CustomDataWrapper from "../../CustomDataWrapper";
import React from "react";
import {TestPiece} from "../../../models/TestPiece";
import {Test} from "../../../models/Test";
import {TestPieceType} from "../../../models/TestPieceType";

interface IProps {
  modalData: TestPiece
  allGroups: Test[]
  pieceTypes: TestPieceType[]
  onChange: (v: TestPiece) => void
  modalLocked?: boolean
}

const CustomData = ({modalData, allGroups, modalLocked, pieceTypes, onChange}: IProps) => {


  // Hand change in modal but for custom data
  const handleChangeCustomData = (index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value = event.target.value; // Get value from target
    let data = [...modalData.data]; // Get All Data from state
    data[index] = value; // Set Data's index to new value
    const newState = {...modalData}; // Get state
    newState.data = data; // Updated data in state
    onChange(newState); // Update state
  }

  // Add Custom data in modal
  const addCustomData = (insertIndex: number) => {
    const data = {...modalData};
    data.data.splice(insertIndex, 0, ""); // Insert empty string at index
    onChange(data);
  }

  // Remove custom data in modal
  const removeCustomData = (index: number) => {
    const data = {...modalData};
    data.data.splice(index, 1); //  Delete 1 item at array index
    if (data.data.length < 1) data.data = [""] // If our array is empty, populate it with one empty string
    onChange(data);
  }

  // Render Group Dropdown for piece 22
  const renderGroupDropdown = () => {
    return (
      <TextField
        sx={{my: 1}}
        select
        fullWidth
        variant={"outlined"}
        value={(modalData.data.length > 0) ? modalData.data[0] : ''}
        onChange={(e) => handleChangeCustomData(0, e)}
        inputProps={{disabled: modalLocked}}
        label={"Group to Execute"}
      >
        <MenuItem key={-1} value={""}>Please Select</MenuItem>
        {allGroups.map((type: any, index: number) =>
          <MenuItem key={index} value={type.test_key}>{type.name}</MenuItem>
        )}
      </TextField>
    );
  }

  // Piece 22 is Variable Update
  const renderPiece22 = () => {
    return (
      <>
        {/* Top Row */}
        <Grid container spacing={2}>

          {/* Var to update */}
          <Grid item lg={6} xs={12}>
            <TextField
              sx={{mt: 1}}
              select
              fullWidth
              variant={"outlined"}
              label={"Update Variables: "}
              value={(modalData.data[0]) ? modalData.data[0] : OnFailVarAction.None}
              onChange={(e) => {
                handleChangeCustomData(0, e)
              }}
              inputProps={{disabled: modalLocked}}
            >
              <MenuItem key={0} value={OnFailVarAction.None}>Nothing</MenuItem>
              <MenuItem key={1} value={OnFailVarAction.IncByOne}>Initialize at 0</MenuItem>
              <MenuItem key={2} value={OnFailVarAction.IncByOne}>Increment by One</MenuItem>
              <MenuItem key={3} value={OnFailVarAction.DecByOne}>Decrement by One</MenuItem>
              <MenuItem key={4} value={OnFailVarAction.Set}>Set to Value</MenuItem>
            </TextField>
          </Grid>

          {/* Update Type */}
          <Grid item lg={6} xs={12}>
            <TextField
              sx={{my: 1, mr: 2}}
              fullWidth
              variant={"outlined"}
              label={"Variable Name: "}
              value={(modalData.data[1]) ? modalData.data[1] : ""}
              onChange={(e) => handleChangeCustomData(1, e)}
              inputProps={{disabled: modalLocked}}
            />
          </Grid>
        </Grid>

        { /* Value to set */}
        {modalData.data[0] === OnFailVarAction.Set &&
          <TextField
            sx={{my: 1}}
            fullWidth
            variant={"outlined"}
            label={"Value to Set: "}
            value={(modalData.data[2]) ? modalData.data[2] : ""}
            onChange={(e) => handleChangeCustomData(2, e)}
            inputProps={{disabled: modalLocked}}
          />
        }
      </>
    )
  }

  // Piece 27 and 28 are type on image
  const renderTypeOnImages = () => {
    const opts = Object.keys(ImageMap).map((key) => <MenuItem key={key} value={key}>{ImageMap[key]}</MenuItem>);
    return (
      <>
        {
          modalData.data.map((val: string, index: number) =>
            <CustomDataWrapper
              key={index}
              add={addCustomData}
              remove={removeCustomData}
              canRemove={index !== 0 || modalData.data.length > 1}
              supportsVd
              field={
                <TextField
                  select
                  fullWidth
                  variant={"outlined"}
                  label={`Image to "Type": `}
                  value={val ?? 'ppc_front'}
                  onChange={(e) => handleChangeCustomData(index, e)}
                  inputProps={{disabled: modalLocked}}
                >
                  {opts}
                </TextField>
              }
              index={index}
              disabled={modalLocked}
            />
          )
        }
      </>
    )
  }

  // Render Generic custom data
  const renderGenericCustomData = () => {
    // Get custom labels
    let labels: any[] = (
      pieceTypes &&
      modalData.piece_key !== undefined &&
      pieceTypes[modalData.piece_key] &&
      Array.isArray(pieceTypes[modalData.piece_key].custom_data_labels)
    ) ?
      pieceTypes[modalData.piece_key].custom_data_labels : [];

    return (
      <>
        {
          modalData.data.map((val: string, index: number) =>
            <CustomDataWrapper
              key={index}
              add={addCustomData}
              remove={removeCustomData}
              canRemove={(index !== 0 || modalData.data.length > 1)}
              supportsVd={
                pieceTypes[modalData.piece_key] &&
                pieceTypes[modalData.piece_key].supports_vd &&
                modalData.variable_data
              }
              field={
                <TextField
                  fullWidth
                  variant={"outlined"}
                  value={val ?? ""}
                  label={
                    labels[index] && !modalData.variable_data ?
                      labels[index] :
                      (labels[0] && modalData.variable_data) ?
                        `${labels[0]} (Instance #${index + 1})` :
                        `Custom Data ${index + 1}`}
                  onChange={(e) => handleChangeCustomData(index, e)}
                  inputProps={{disabled: modalLocked}}
                />
              }
              index={index}
              disabled={modalLocked}
            />
          )
        }
      </>
    )
  }

  // Render Fields related to custom data
  const render = (): JSX.Element | null => {
    // Some Pieces have custom renderers, like the Variable update/group selector
    if (modalData && modalData.piece_key && modalData.piece_key === 22) { // Variable
      return renderPiece22();
    } else if (modalData && modalData.piece_key && modalData.piece_key === 17) { // Group
      return renderGroupDropdown();
    } else if (modalData && modalData.piece_key && modalData.piece_key === 27) { // Type Image on Field
      return renderTypeOnImages();
    } else if (modalData && modalData.piece_key && modalData.piece_key === 28) { // Force Type Image on Field
      return renderTypeOnImages();
    } else if (modalData) {
      return renderGenericCustomData()
    } else {
      return null;
    }
  }

  return (
    <>{render()}</>
  );
}

export default CustomData;
