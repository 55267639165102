export const ModalMode = { // Enum for defining the behavior/view of the modal
  Modify: 0,
  Create: 1,
  View: 2
}

export const LocatorType = {
  None: 0,
  XPath: 1,
  Css: 2,
  Js: 3
}
export const LogLevel = {
  Info: 0,
  Error: 1,
  TestFail: 2,
  TestSuccess: 3
}
export enum PageType {
  Test = "Test",
  Group = "Group"
}

export const BrowserType = {
  Chrome: "chrome",
  Edge: 'edgehtml',
  Firefox: 'firefox',
  "Chrome iPhoneX": 'chrome_iphonex',
  "Chrome iPhone 6/7/8/8 Plus": 'chrome_iphone678plus',
  "Chrome iPhone 6/7/8": 'chrome_iphone678',
  "ChromeiPhone 5/SE": 'chrome_iphone5se',
  "Chrome iPad": 'chrome_ipad',
  "Chrome iPad Pro": 'chrome_ipadpro',
  "Chrome Galaxy Fold": 'chrome_galaxyfold',
  "Chrome Pixel 2": 'chrome_pixel2',
  "Chrome Pixel 2XL": 'chrome_pixel2xl'
} as any;

export const OnFailVarAction = {
  IncByOne: "inc",
  DecByOne: 'dec',
  Set: 'set',
  None: 'none'
}

export const ToastStatus = {
  Success: "success",
  Warning: 'warning',
  Danger: 'danger',
  Info: 'info',
}

export const ImageMap = {
  ppc_front: "Example Passport Card Front",
  ppc_back: "Example Passport Card Back",
  dl_front: "Example Drivers License Front",
  dl_back: "Example Drivers License Back",
  ssn_front: "Example SSN Card Front",
  ssn_back: "Example SSN Card Back",
  small_dog: "Small, Upside-down Dog",
  joel_duck: "Joel Duck",
  four_img_pdf: "4-Image PDF",
  many_img_pdf: "Many-Image PDF"
} as any
