import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useState} from "react";
import {LogLevel, PageType} from "../../core/Enums";
import {TestPiece} from "../../models/TestPiece";
import TestPieceModal from "../TestPieceModal/TestPieceModal";
import {LogPiece} from "../../models/LogPiece";
import {TestPieceType} from "../../models/TestPieceType";
import {Test} from "../../models/Test";

interface IProps {
  testResults: LogPiece[],
  pieceTypes: TestPieceType[],
  groups: Test[],
  pageType: PageType
}

const TestResultTab = ({testResults, pieceTypes, groups, pageType}: IProps) => {

  const [modalData, setModalData] = useState<TestPiece>({} as TestPiece);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalLocked, setModalLocked] = useState<boolean>(false);
  const [openLogId, setOpenLogId] = useState<string>();

  // Handle a click on a log's row (open modal, load data, etc)
  const rowClickLog = (piece: any, hasScreenshot: string) => {
    setModalData(piece);
    setOpenLogId(hasScreenshot);
    setModalOpen(true);
    setModalLocked(true);
  }

  const TestResults = () => {
    return (
      <>
        {testResults &&
          testResults.map((r: any, i: number) => ( // Render All Test Results
            <TableRow hover key={i} style={{cursor: 'pointer'}} onClick={() => {
              if (r.piece) rowClickLog(r.piece, (r.screenshot_available) ? r.log_piece_key : undefined)
            }}>
              {/* Comment */}
              <TableCell>
                {(pageType === PageType.Test && r.piece.group_key) ? `[${r.piece.group_key}] ` : ''}{r.piece.comment}
              </TableCell>

              {/* Piece Type Name */}
              <TableCell>
                {(pieceTypes && pieceTypes.length > 0) ? pieceTypes[r.piece.piece_key].name : r.piece.piece_key}
              </TableCell>

              {/* Message */}
              <TableCell>{r.message}</TableCell>

              {/* Success/Fail */}
              <TableCell
                sx={{color: ((!r.level) ? '' : (r.level === LogLevel.TestSuccess) ? 'success.main' : 'error.main')}}
              >
                {(!r.level) ? 'Test Not Completed!' : (r.level === LogLevel.TestSuccess) ? 'Success' : 'Fail'}
              </TableCell>
            </TableRow>
          ))
        }
      </>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{mb: 0}} size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell key={0} scope='col'>Test Comment</TableCell>
              <TableCell key={1} scope='col'>Test Type</TableCell>
              <TableCell key={2} scope='col'>Response Message</TableCell>
              <TableCell key={3} scope='col'>Test Status</TableCell>
            </TableRow>
          </TableHead>
          <tbody>
          <TestResults/>
          </tbody>
        </Table>
      </TableContainer>


      {/* Modal */}
      <TestPieceModal key={modalData.test_piece_key} modalClose={() => setModalOpen(false)}
                      propsModalSave={() => {
                      }}
                      open={modalOpen} pieceTypes={pieceTypes} modalLocked={modalLocked}
                      modalData={modalData} groups={groups} logKey={openLogId}
      />
    </>
  )
}

export default TestResultTab;
