import {MenuItem, Paper, TextField, Typography} from "@mui/material";
import React from "react";
import {Log} from "../../models/Log";

interface IProps {
  testLogs: Log[]
  selectedLog: string,
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const LogSelector = ({onChange, testLogs, selectedLog}: IProps) => {
  let exists = false;
  for (const log of testLogs) {
    if (log.log_key === selectedLog) {
      exists = true;
      break;
    }
  }

  return (
    <Paper sx={{m: 2}}>
      {testLogs && testLogs.length > 0 && selectedLog && exists &&
        <TextField select fullWidth variant={'outlined'} label={'Select Logs to View'}
                   value={(selectedLog.substr(0)) ? selectedLog.substr(0) : ''}
                   onChange={onChange}
        >
          {
            testLogs.map((log: any) => {
              const startDate = new Date(log.start_date);
              const dateString = startDate.toDateString() + ' at ' + startDate.toTimeString();
              const passString = log.pass ? "(Success)" : (log.pass === false) ? "(Fail)" : (log.running) ? "(Running)" : "(Unknown)";
              const nameString = (!log.test_locally && log.test_config) ? `${dateString} - ${log.name}` : dateString;
              return (
                <MenuItem value={log.log_key} key={log.log_key}>
                  <Typography display={"inline"}>{nameString}</Typography>
                  <Typography
                    display={"inline"}
                    color={log.pass ? "success.main" : (log.pass === false) ? "error" : (log.running) ? "warning.main" : "disabled"}
                  >
                    {` ${passString}`}
                  </Typography>
                </MenuItem>
              )
            })
          }
        </TextField>
      }
    </Paper>
  )
}

export default LogSelector;
