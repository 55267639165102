import React, {useEffect, useState} from "react";
import {LocatorType, OnFailVarAction} from "../../core/Enums"
import {
  Button,
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
  MenuItem, Box, Typography, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Tooltip
} from "@mui/material";
import {Delete, Save, UploadFile} from "@mui/icons-material";
import {ApiHelper as api} from '../../data/helpers'
import {useMsal} from "@azure/msal-react";
import {TestPiece, validateTestPiece} from "../../models/TestPiece";
import {Test} from "../../models/Test";
import {TestPieceType} from "../../models/TestPieceType";
import CustomData from "./CustomData";

interface IProps {
  pieceTypes: TestPieceType[],
  modalData: TestPiece,
  open: boolean,
  modalLocked: boolean,
  groups: Test[],
  modalClose: () => void,
  propsModalSave: (piece: TestPiece) => void,
  logKey?: string,
  onDoUploadRecording?: () => any
}

const TestPieceModal = ({
                          groups: allGroups,
                          propsModalSave,
                          pieceTypes,
                          modalLocked,
                          open,
                          modalClose,
                          modalData: beginData,
                          logKey,
                          onDoUploadRecording
                        }: IProps) => {

  // Hooks
  const {instance} = useMsal();

  // State
  const [modalData, setModalData] = useState(validateTestPiece(beginData));
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (logKey && logKey !== '') {
      api.getScreenshot(instance, logKey).then(data => {
        if (data.length > 0 && data[0]) setImage(data[0].data);
      })
    } else {
      setImage(undefined);
    }
  }, [instance, logKey]); // eslint-disable-line

  // Handle data after user click "save"
  const onModalSave = () => {
    propsModalSave(modalData);
  }

  // when user click screenshot button
  const onModalOpenScreenshot = () => {
    let newWindow = window.open("", "Screenshot from Selenium", "menubar=0,toolbar=0,location=0,status=0,scrollbars=0,height=1080,width=1920")
    newWindow?.document.write("<head><title>Screenshot from Selenium</title></head>");
    newWindow?.document.write("<iframe width='100%' height='100%' src='data:image/jpeg;base64," + encodeURI(image ?? "") + "'></iframe>");
  }

  // Handle Change in Modal
  const handleChange = (key: keyof TestPiece, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value: string | number = event.target.value;
    let data = {...modalData};
    if (key === "expect_failure" || key === "log_result" || key === "variable_data") {
      // @ts-ignore
      value = event.target.checked

      // Auto select locator type
    } else if (key === "locator") {
      if (value.indexOf(" > ") > -1 || value.indexOf("nth-child") > -1 || value.indexOf("=\"") > -1) {
        data.locator_type = LocatorType.Css
      } else if (value.indexOf("/") > -1) {
        data.locator_type = LocatorType.XPath
      }
      // Piece type changed, determine if we need to add/remove custom data
    } else if (key === "piece_key") {
      if (!pieceTypes) return;
      // Parse key
      value = parseInt(value);
      // Check that keys exist
      if (!pieceTypes[value] || !pieceTypes[data.piece_key]) return;
      // If the category changed, clear the custom data
      if (pieceTypes[value].catagory_key !== pieceTypes[data.piece_key].catagory_key) {
        data.data = [''];
      }
      //
    }

    // @ts-ignore
    data[key] = value;

    // Validate custom data
    data = validateTestPiece(data, pieceTypes);

    // Update state
    setModalData(data);
  }

  // Returns if a locator is required for a specific type
  const requiresLocator = () => {
    return (
      pieceTypes &&
      pieceTypes.length > 0 &&
      modalData.piece_key !== undefined &&
      pieceTypes[modalData.piece_key] &&
      pieceTypes[modalData.piece_key].requires_locator
    )
  }

  // Returns if variable data is supported for a specific type
  const supportsVariableData = () => {
    return (
      pieceTypes &&
      pieceTypes.length > 0 &&
      modalData.piece_key !== undefined &&
      pieceTypes[modalData.piece_key] &&
      pieceTypes[modalData.piece_key].supports_vd
    )
  }

  return (
    <Dialog
      open={open}
      onClose={modalClose}
      closeAfterTransition
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle sx={{display: "flex", alignItems: "center"}}>
        {modalLocked ?
          'View' :
          'Edit'
        }
        {!modalLocked &&
          <Tooltip title={'Import Chrome Recording'}>
            <IconButton sx={{ml: "auto"}} size={"small"} onClick={() => onDoUploadRecording ? onDoUploadRecording() : {}}>
              <UploadFile/>
            </IconButton>
          </Tooltip>
        }
      </DialogTitle>
      <DialogContent dividers>

        {/* Screenshot */}
        {image &&
          <Grid sx={{textAlign: "center", my: 1}} container spacing={2}>
            <Grid item lg={12} xs={12}>
              <img
                alt={"Selenium Screenshot"}
                style={{maxHeight: "30vh"}}
                src={"data:image/jpeg;base64," + image}
                onClick={() => onModalOpenScreenshot()}
              />
            </Grid>
          </Grid>
        }

        {/* Comment/Name */}
        <TextField
          fullWidth
          sx={{my: 1}}
          variant={"outlined"}
          placeholder="A Brief Identifier"
          inputProps={{disabled: modalLocked}}
          label={"Comment: "}
          value={modalData.comment}
          onChange={(e) => handleChange("comment", e)}
        />

        {/* Piece Types/Locator Type */}
        <Grid container sx={{my: 1}} spacing={2}>

          {/* Piece Types */}
          {pieceTypes && pieceTypes.length > 0 && modalData.piece_key !== undefined &&
            <Grid item lg={6} xs={12}>
              <TextField
                select
                fullWidth
                label={"Piece Types: "}
                value={(modalData.piece_key) ? modalData.piece_key : 0}
                variant={"outlined"}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange("piece_key", e)}
                inputProps={{disabled: modalLocked}}
              >
                {pieceTypes.map((type, index) =>
                  <MenuItem
                    key={index}
                    value={type.key}
                    data-toggle={"tooltip"}
                    title={type.description}
                  >
                    {type.name}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
          }

          {/* Locator Type*/}
          <Grid item lg={6} xs={12}>
            <TextField
              select
              fullWidth
              variant={"outlined"}
              label={"Locate Item By"}
              inputProps={{disabled: (!requiresLocator() || modalLocked)}}
              value={(modalData.locator_type) ? modalData.locator_type : LocatorType.None}
              onChange={(e) => handleChange("locator_type", e)}
            >
              <MenuItem value={LocatorType.None}>None</MenuItem>
              <MenuItem value={LocatorType.Css}>CSS</MenuItem>
              <MenuItem value={LocatorType.XPath}>xPath</MenuItem>
            </TextField>

          </Grid>
        </Grid>

        {/* Element Locators */}
        {requiresLocator() &&
          <TextField
            fullWidth
            sx={{my: 1}}
            variant={"outlined"}
            label={`Element Locator`}
            value={modalData.locator}
            inputProps={{disabled: modalLocked}}
            onChange={(e) => handleChange("locator", e)}
          />
        }

        {/* On Fail Update */}
        <Grid container sx={{my: 1}} spacing={2}>

          {/* On Fail Action */}
          <Grid item lg={6} xs={12}>
            <TextField
              select
              fullWidth
              variant={"outlined"}
              label={"On Fail, Update Variables: "}
              value={(modalData.on_fail_variable_action) ? modalData.on_fail_variable_action : OnFailVarAction.None}
              onChange={(e) => handleChange("on_fail_variable_action", e)}
              inputProps={{disabled: modalLocked}}
            >
              <MenuItem value={OnFailVarAction.None}>Nothing</MenuItem>
              <MenuItem value={OnFailVarAction.IncByOne}>Increment by One</MenuItem>
              <MenuItem value={OnFailVarAction.DecByOne}>Decrement by One</MenuItem>
              <MenuItem value={OnFailVarAction.Set}>Set to Value</MenuItem>
            </TextField>
          </Grid>

          {/* On Fail Variable */}
          <Grid item lg={6} xs={12}>
            <TextField
              fullWidth
              variant={"outlined"}
              label={"Variable Name: "}
              value={(modalData.on_fail_variable) ? modalData.on_fail_variable : ""}
              onChange={(e) => handleChange("on_fail_variable", e)}
              inputProps={{disabled: modalLocked}}
            />
          </Grid>

        </Grid>

        {/* On Fail Variable Value to Set*/}
        {modalData.on_fail_variable_action === OnFailVarAction.Set &&
          <TextField
            fullWidth
            sx={{my: 1}}
            variant={"outlined"}
            label={"Value to Set: "}
            value={(modalData.on_fail_variable_value) ? modalData.on_fail_variable_value : ""}
            onChange={(e) => handleChange("on_fail_variable_value", e)}
            inputProps={{disabled: modalLocked}}
          />
        }

        {/* On Fail Execute Box */}
        <TextField
          select
          fullWidth
          sx={{my: 1}}
          variant={"outlined"}
          label={"On Fail Execute: "}
          value={(modalData.on_fail_execute) ? modalData.on_fail_execute : ""}
          onChange={(e) => handleChange("on_fail_execute", e)}
          inputProps={{disabled: modalLocked}}
        >
          <MenuItem key={-1} value={""}>None</MenuItem>
          {allGroups.map((type: any, index: number) =>
            <MenuItem key={index} value={type.test_key}>{type.name}</MenuItem>
          )}
        </TextField>

        {/* Log Result / Expect Failure */}
        <Grid container sx={{my: 1}} spacing={2}>

          {/* Log Result */}
          <Grid item lg={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleChange("log_result", e)}
                  checked={modalData.log_result}
                  disabled={modalLocked}
                />
              }
              label={"Log Result (Pass/Fail)"}
            />
          </Grid>

          {/* Expect Failure */}
          <Grid item lg={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange("expect_failure", e)}
                  checked={modalData.expect_failure}
                  disabled={modalLocked}
                />
              }
              label={"Expect Failure"}
            />
          </Grid>

        </Grid>


        <Typography>Custom Data:</Typography>

        {/* Custom Data */}
        <Box sx={{my: 1}}>
          <CustomData
            modalData={modalData}
            pieceTypes={pieceTypes}
            modalLocked={modalLocked}
            onChange={setModalData}
            allGroups={allGroups}
          />
        </Box>

        {/* Supports Variable Data */}
        {supportsVariableData() &&
          <FormControlLabel
            sx={{my: 1}}
            control={
              <Checkbox
                onChange={(e) => handleChange("variable_data", e)}
                checked={modalData.variable_data}
                disabled={modalLocked}
              />
            }
            label={"Variable Data (use new custom data for every test instance)"}
          />
        }

      </DialogContent>

      {/* Save/Cancel */}
      <DialogActions>

        {/* Cancel Button */}
        <Button
          sx={{m: 1}}
          startIcon={<Delete/>}
          color={"error"}
          onClick={() => modalClose()}
        >
          Cancel
        </Button>

        {/* Save Button */}
        <Button
          sx={{m: 1}}
          hidden={modalLocked}
          startIcon={<Save/>}
          color={"primary"}
          onClick={() => onModalSave()}
        >
          Save
        </Button>

      </DialogActions>
    </Dialog>
  )
}


export default TestPieceModal;
