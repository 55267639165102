import {Configuration, PopupRequest} from "@azure/msal-browser";
var CryptoJS = require("crypto-js");

export const msalConfig: Configuration = {

    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        authority: 'https://login.microsoftonline.com/common/',
        redirectUri: process.env.NODE_ENV === "development" ? 'http://localhost:3000/' : process.env.PUBLIC_URL,
        postLogoutRedirectUri: process.env.NODE_ENV === "development" ? 'http://localhost:3000/' : process.env.PUBLIC_URL
    }

}

export const loginRequest: PopupRequest = {
    scopes: ["openid", "profile", "email"],
    nonce: CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex)
}
