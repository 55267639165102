import {LocatorType} from "../../core/Enums";
import {Browser} from "../../models/Browser";

const formatLongText = (text: string, length = 25) => {
  if (text.length > length) {
    return text.substr(0, length) + ' (...)';
  } else {
    return text;
  }
}

const getNewPiece = (customOrder = 0, testKey: string): any => {
  return {
    'test_key': testKey,
    'test_piece_key': testKey + '_' + customOrder,
    'piece_key': 0,
    'order': customOrder,
    'comment': 'New Piece',
    'locator': '',
    'locator_type': LocatorType.None,
    'data': [''],
    'variable_data': 'false'
  };
}

// Calculate test results from logs
const calculateTestResultsFromLogs = (logPieces: any[]): any[] => {
  const endResults = [] as any[];
  for (const l of logPieces) {
    if (l.piece && l.piece.log_result === true && l.piece_key !== 17) {
      endResults.push(l);
    }
  }
  return endResults;
}

const parseBstack = (browsers: Browser[]) => {
  const bstackMap = {} as { [key: string]: any };
  for (const browser of browsers) {
    if (browser.os) { // check for os
      if (!bstackMap[browser.os]) bstackMap[browser.os] = {} // make sure our os map exist
      if (browser.os_version) { // check for version
        if (!bstackMap[browser.os][browser.os_version]) bstackMap[browser.os][browser.os_version] = {} // make sure version map exists
        if (browser.browser) { // check for browser
          if (!bstackMap[browser.os][browser.os_version][browser.browser]) bstackMap[browser.os][browser.os_version][browser.browser] = [] // make sure browser array exists
          bstackMap[browser.os][browser.os_version][browser.browser].push(browser); // store browser data
        }
      }
    }
  }
  return bstackMap;
}


export {
  formatLongText,
  getNewPiece,
  calculateTestResultsFromLogs,
  parseBstack
}
