import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import React, {useEffect, useState} from "react";
import {LocatorType, ModalMode, PageType, ToastStatus} from "../../core/Enums";
import {formatLongText, getNewPiece} from "../../data/helpers/FunctionHelpers";
import {ApiHelper as api, notify} from "../../data/helpers";
import {AddCircle, FileCopy, RemoveCircle} from "@mui/icons-material";
import {TestPiece} from "../../models/TestPiece";
import {useMsal} from "@azure/msal-react";
import TestPieceModal from "../TestPieceModal/TestPieceModal";
import UploadRecordingDialog from "../UploadRecordingDialog";

interface IProps {
  testKey: string,
  pageType: PageType,
  pieceTypes: any[],
  groups: any[]
}

const TestDesignerTab = ({testKey, pageType, pieceTypes, groups}: IProps) => {

  // Authentication
  const {instance} = useMsal()

  // State
  const [pieces, setPieces] = useState<TestPiece[]>([]);
  const [pieceLoading, setPieceLoading] = useState<number>(-1);
  const [modalData, setModalData] = useState<TestPiece>({} as TestPiece);
  const [modalDataIndex, setModalDataIndex] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState(ModalMode.Create);
  const [modalLocked, setModalLocked] = useState<boolean>(false);
  const [uploadRecordingOpen, setUploadRecordingOpen] = useState<boolean>(false);


  useEffect(() => {
    // Get Test Pieces
    api.getAllTestPieces(testKey, instance).then((data) => {
      if (Array.isArray(data) && data.length > 0) {
        setPieces(data);
      } else {
        setPieces([getNewPiece(0, testKey)]);
        // updateTestResults([]);
      }
    }).catch((err: any) => {
      notify('Failed to test pieces: ' + err.message, ToastStatus.Danger)
    });
  }, []); // eslint-disable-line

  // Clear loading piece when modal closes
  useEffect(() => {
    if (!modalOpen) setPieceLoading(-1);
  }, [modalOpen]); // eslint-disable-line

  // Handle data after user click "save"
  const onRemoteModalSave = (piece: TestPiece) => {
    const newPieces = [...pieces]
    if (modalMode === ModalMode.Create) {
      newPieces.splice(modalDataIndex, 0, piece);
    } else if (modalMode === ModalMode.Modify) {
      newPieces[modalDataIndex] = piece;
    }
    reindexPiecesThenPost(newPieces)
  }

  // Handle a click on a row (open modal, load data, etc)
  const rowClick = (piece: TestPiece, index: number) => {
    piece = {...piece};
    piece.data = [...piece.data]
    setModalData(piece);
    setModalOpen(true);
    setModalMode(ModalMode.Modify);
    setPieceLoading(index)
    setModalDataIndex(index);
    setModalLocked(false);
  }

  // Handle drag/drop event on test piece rows.
  const handleDragDrop = (result: any) => {
    if (!result.destination) { // no change
      return;
    }

    const oldPieces = [...pieces];
    const newPieces = [...pieces];
    const deleted = newPieces.splice(result.source.index, 1);
    newPieces.splice(result.destination.index, 0, deleted[0]);
    setPieces(newPieces)
    setPieceLoading(result.destination.index);

    reindexPiecesThenPost(newPieces, oldPieces);
  }

  // Add test piece
  const addPiece = (index: number) => {
    const newData = getNewPiece(index, testKey);
    // Set State
    setModalData(newData);
    setModalOpen(true);
    setModalMode(ModalMode.Create);
    setModalDataIndex(index);
    setModalLocked(false);
  }

  // Remove test piece
  const removePiece = (index: number) => {
    setPieceLoading(index);
    api.deletePiece(pieces[index].test_piece_key, instance).then((res) => {
      // Unset open piece
      setPieceLoading(-1);
      // Copy array
      const newPieces = [...pieces];
      // Delete 1 item at array index
      newPieces.splice(index, 1);
      // If our array is empty, populate it with one base object
      if (newPieces.length < 1) {
        newPieces.push(getNewPiece(0, testKey))
      }
      // Set State
      setModalOpen(false);
      setPieces(newPieces);
    }).catch((err: any) => {
      notify(`Failed to delete key: ${err.message}`, ToastStatus.Danger);
    });
  }

  // Duplicate test piece
  const duplicatePiece = (index: number, piece: TestPiece) => {
    const newPiece = {...piece};
    newPiece.data = [...piece.data];
    newPiece.comment += ' (copy)';
    // Set State
    setModalData(newPiece);
    setModalLocked(false);
    setModalOpen(true);
    setModalMode(ModalMode.Create);
    setModalDataIndex(index);
  }

  // Reindex "order" property and then update the database
  const reindexPiecesThenPost = (newPieces: any[], oldPieces?: any[]) => {
    // Reindex array order, and do some data formatting and validation
    newPieces.map((data: TestPiece, index: number) => {
      data.order = index;
      data.test_piece_key = data.test_key + '_' + data.order;
      data.locator_type = (pieceTypes[data.piece_key].requires_locator) ? data.locator_type : LocatorType.None;

      // @ts-ignore
      data.piece_key = parseInt(data.piece_key)
      return data;
    })
    api.postPieces(newPieces, instance).then(() => {
      setPieces(newPieces);
      setModalOpen(false);
      setPieceLoading(-1);
    }).catch((err: any) => {
      setPieceLoading(-1);
      if (oldPieces) setPieces(oldPieces);
      notify("Failed to Update Test Piece: " + err.message, ToastStatus.Danger);
    })
  }

  /**
   * Open import recording modal
   */
  const openImportRecording = () => {
    setModalOpen(false);
    setUploadRecordingOpen(true);
  }

  /**
   * Close import recording modal
   * @param cancel Canceled?
   * @param newPieces New pieces from upload
   */
  const closeImportRecording = (cancel: boolean, newPieces: TestPiece[]) => {
    if(cancel) {
      setUploadRecordingOpen(false);
      setModalOpen(true);
    } else {
      setPieces(newPieces);
      setUploadRecordingOpen(false);
    }
  }

  return (
    <>
      {/* Loading */}
      {pieces.length < 1 &&
        <LinearProgress/>
      }
      {/* Table */}
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{mb: 1}} size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell key={0} scope='col'>{pageType} Piece Comment</TableCell>
              <TableCell key={1} scope='col'>Piece Type</TableCell>
              <TableCell key={2} scope='col'>XPath/Css/JS</TableCell>
              <TableCell key={3} scope='col'>Data 1</TableCell>
              <TableCell key={5} scope='col'>Data 2</TableCell>
              <TableCell key={6} scope='col'>Piece Specific Data?</TableCell>
              <TableCell key={7} scope='col' style={{width: '5px'}}>Add</TableCell>
              <TableCell key={8} scope='col' style={{width: '5px'}}>Copy</TableCell>
              <TableCell key={9} scope='col' style={{width: '5px'}}>Remove</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={(event) => handleDragDrop(event)}>
            <Droppable droppableId={'droppable'}>
              {(provided, snapshot) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {pieces.length > 0 &&
                    pieces.map((p, i: number) => {
                      // @ts-ignore
                      p.locator_type = parseInt(p.locator_type);
                      const style = {backgroundColor: pieceLoading === i ? 'rgba(255, 0, 0, 0.25)' : undefined}
                      return (
                        <Draggable
                          key={p.test_piece_key}
                          draggableId={p.test_piece_key}
                          index={i}
                          isDragDisabled={pieceLoading === i}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              key={p.piece_key}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              hover
                              selected={pieceLoading === i}
                            >
                              {/* Comment/Title */}
                              <TableCell style={style} onClick={() => rowClick(p, i)}>{p.comment}</TableCell>

                              {/* Piece Type */}
                              <TableCell
                                style={style}
                                onClick={() => rowClick(p, i)}
                              >
                                {(pieceTypes && pieceTypes.length > 0 && p.piece_key !== undefined && pieceTypes[p.piece_key]) ? pieceTypes[p.piece_key].name : ''}
                              </TableCell>

                              {/* Locator Type */}
                              <TableCell
                                style={style}
                                onClick={() => rowClick(p, i)}
                              >
                                {(p.locator_type === LocatorType.Css) ? 'Css' : (p.locator_type === LocatorType.XPath) ? 'XPath' : (p.locator_type === LocatorType.Js) ? 'JS' : 'n/a'}
                              </TableCell>

                              {/* Data 1 */}
                              <TableCell
                                style={style}
                                onClick={() => rowClick(p, i)}
                              >
                                {(p.data && p.data.length > 0) ? formatLongText(p.data[0]) : 'n/a'}
                              </TableCell>

                              {/* Data 2 */}
                              <TableCell
                                style={style}
                                onClick={() => rowClick(p, i)}
                              >
                                {(p.data && p.data.length > 1) ? formatLongText(p.data[1]) : 'n/a'}
                              </TableCell>

                              {/* Variable Data */}
                              <TableCell
                                style={style}
                                onClick={() => rowClick(p, i)}
                              >
                                {(p.variable_data && p.variable_data === true) ? 'Yes' : 'No'}
                              </TableCell>

                              {/* Add Button */}
                              <TableCell style={style} sx={{p: 0}}>
                                <IconButton onClick={() => addPiece(i + 1)} color={"primary"}>
                                  <AddCircle/>
                                </IconButton>
                              </TableCell>

                              {/* Duplicate Button */}
                              <TableCell style={style} sx={{p: 0}}>
                                <IconButton onClick={() => duplicatePiece(i, p)} color={"warning"}>
                                  <FileCopy/>
                                </IconButton>
                              </TableCell>

                              {/* Remove Button */}
                              <TableCell style={style} sx={{p: 0}}>
                                <IconButton onClick={() => removePiece(i)} color={"error"}>
                                  <RemoveCircle/>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    })
                  }
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>


      {/* Modal */}
      {pieceTypes && groups &&
        <TestPieceModal key={modalData.test_piece_key}
                        modalClose={() => setModalOpen(false)}
                        propsModalSave={onRemoteModalSave.bind(this)}
                        open={modalOpen}
                        pieceTypes={pieceTypes}
                        modalLocked={modalLocked}
                        modalData={modalData}
                        groups={groups}
                        logKey={''}
                        onDoUploadRecording={() => openImportRecording()}
        />
      }

      {/* Import Recording */}
      <UploadRecordingDialog
        open={uploadRecordingOpen}
        onClose={closeImportRecording}
        testKey={testKey}
        insertIndex={modalDataIndex}
      />
    </>
  )
}

export default TestDesignerTab;
