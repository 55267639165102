import {BrowserstackConfig, Test} from "../../models/Test";
import {Divider, Grid, IconButton, LinearProgress, MenuItem, TextField} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {ApiHelper as api} from "../../data/helpers";
import {useMsal} from "@azure/msal-react";
import {parseBstack} from "../../data/helpers/FunctionHelpers";

const allResolutions = ["1024x768", "1280x800", "1280x1024", "1366x768", "1440x900", "1680x1050", "1600x1200", "1920x1200", "1920x1080", "2048x1536"]

interface IProps {
  test: Test,
  setTest: (v: Test) => void,
}

const BrowserstackConfigPane = ({test, setTest}: IProps) => {
  // Hooks
  const {instance} = useMsal();

  // State
  const [allConfigs, setAllConfigs] = useState({} as { [key: string]: any });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    // Get All Browserstack Devices and map them to something that's easier to parse and display
    api.getBrowsers(instance).then((browsers) => {
      setAllConfigs(parseBstack(browsers));
      setLoading(false);
    });
  }, []) // eslint-disable-line

  const updateBrowserStackConfig = (index: number, property?: keyof BrowserstackConfig, value?: any) => {
    const oldData = {...test.test_properties}
    oldData.browserstack_configs = [...test.test_properties.browserstack_configs]
    if (index === -1) {
      oldData.browserstack_configs.push({} as BrowserstackConfig); // Push blank
      setTest({...test, test_properties: oldData});
    } else if (index > -1 && property) {
      oldData.browserstack_configs[index][property] = value
      if (property === 'platform') { // If we're changing platform, null out device, browser, and version
        oldData.browserstack_configs[index].version = null;
        oldData.browserstack_configs[index].browser = null;
        oldData.browserstack_configs[index].osVersion = null;
      } else if (property === 'osVersion') { // If we're changing device, null out browser, and version
        oldData.browserstack_configs[index].browser = null;
        oldData.browserstack_configs[index].version = null;
      } else if (property === 'browser') { // If we're changing browser, null out version
        oldData.browserstack_configs[index].version = null;
      }
      setTest({...test, test_properties: oldData});
    }
  }

  const deleteBrowserstackConfig = (index: number) => {
    const old = {...test.test_properties}
    old.browserstack_configs.splice(index, 1);
    if (test.test_properties.browserstack_configs.length < 1) test.test_properties.browserstack_configs.push({platform: "none"} as BrowserstackConfig);
    setTest({...test, test_properties: old});
  }

  const isMobile = (platform: string | null) => {
    return platform === 'ios' || platform === 'android'
  }

  return (
    <>
      {loading &&
        <LinearProgress/>
      }
      {test.test_properties && test.test_properties.browserstack_configs && test.test_properties.browserstack_configs.length > 0 && Object.keys(allConfigs).length > 0 &&
        <>
          {
            test.test_properties.browserstack_configs.map((conf: BrowserstackConfig, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Grid container spacing={2} justifyContent={'space-between'}>

                    {/* Platform Selector */}
                    <Grid item xl={2} md={6} xs={6}>
                      {
                        allConfigs &&
                        <TextField
                          select
                          fullWidth
                          variant={"outlined"}
                          label={"Platform"}
                          value={(conf.platform) ? conf.platform : ''}
                          onChange={(e) => updateBrowserStackConfig(index, 'platform', e.target.value)}
                        >
                          {Object.keys(allConfigs).map((key: string) => <MenuItem key={key}
                                                                                  value={key}>{key}</MenuItem>)}
                        </TextField>
                      }
                    </Grid>

                    {/* OS Version Selector */}
                    <Grid item xl={2} md={6} xs={6}>
                      {
                        allConfigs && conf.platform && allConfigs[conf.platform] &&
                        <TextField
                          select
                          fullWidth
                          variant={"outlined"}
                          label={"OS Version"}
                          value={(conf.osVersion) ? conf.osVersion : ''}
                          onChange={(e) => updateBrowserStackConfig(index, 'osVersion', e.target.value)}
                        >
                          {Object.keys(allConfigs[conf.platform]).map((key: any) => <MenuItem key={key}
                                                                                              value={key}>{key}</MenuItem>)}
                        </TextField>
                      }
                    </Grid>

                    {/* Browser/Device Selector */}
                    <Grid item xl={isMobile(conf.platform) ? 3 : 2} md={isMobile(conf.platform) ? 5 : 2} xs={6}>
                      {
                        conf.platform && conf.osVersion && allConfigs[conf.platform] && allConfigs[conf.platform][conf.osVersion] &&
                        <TextField
                          select
                          fullWidth
                          variant={"outlined"}
                          label={"Browser/Device"}
                          value={(conf.browser) ? conf.browser : ''}
                          onChange={(e) => updateBrowserStackConfig(index, 'browser', e.target.value)}
                        >
                          {Object.keys(allConfigs[conf.platform][conf.osVersion]).map((val: any) => <MenuItem key={val}
                                                                                                              value={val}>{val}</MenuItem>)}
                        </TextField>
                      }
                    </Grid>

                    {/* Browser/Device Version Selector */}
                    <Grid item xl={isMobile(conf.platform) ? 3 : 2} md={isMobile(conf.platform) ? 5 : 2} xs={6}>
                      {conf.platform && conf.osVersion && conf.browser && allConfigs[conf.platform] && allConfigs[conf.platform][conf.osVersion] && allConfigs[conf.platform][conf.osVersion][conf.browser] &&
                        <TextField
                          select
                          fullWidth
                          variant={"outlined"}
                          label={"Version"}
                          value={(conf.version) ? conf.version : ''}
                          onChange={(e) => updateBrowserStackConfig(index, 'version', e.target.value)}
                        >
                          {allConfigs[conf.platform][conf.osVersion][conf.browser].map((val: any, i: number) => (
                            <MenuItem
                              key={val}
                              value={(val.browser_version) ? val.browser_version : (val.device) ? val.device : 'bib' + i}
                            >
                              {(val.browser_version) ? val.browser_version : (val.device) ? val.device : 'Built In Browser'}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                    </Grid>

                    {/* Resolution Selector */}
                    {!isMobile(conf.platform) &&
                      <Grid item xl={2} md={6} xs={12}>
                        {conf.platform && conf.osVersion && conf.browser && conf.version && allConfigs[conf.platform] && allConfigs[conf.platform][conf.osVersion] && allConfigs[conf.platform][conf.osVersion][conf.browser] && !isMobile(conf.platform) &&
                          <TextField
                            select
                            fullWidth
                            variant={"outlined"}
                            label={"Resolution"}
                            value={(conf.resolution) ? conf.resolution : ''}
                            onChange={(e) => updateBrowserStackConfig(index, 'resolution', e.target.value)}
                          >
                            {allResolutions.map((val: any) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
                          </TextField>
                        }
                      </Grid>
                    }

                    {/* Add/Remove Button */}
                    <Grid item xl={1} md={2} xs={2}>
                      <IconButton
                        onClick={() => updateBrowserStackConfig(-1)}
                        size="large"
                        color={"primary"}
                      >
                        <AddCircle/>
                      </IconButton>
                      {test.test_properties.browserstack_configs.length > 1 &&
                        <IconButton
                          onClick={() => deleteBrowserstackConfig(index)}
                          size="large"
                          color={"error"}
                        >
                          <RemoveCircle/>
                        </IconButton>
                      }
                    </Grid>
                  </Grid>
                  <Divider sx={{my: 2}}/>
                </React.Fragment>
              );
            })
          }
        </>
      }
    </>
  );
}

export default BrowserstackConfigPane;
