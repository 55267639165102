import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import {TestPiece} from "../../models/TestPiece";
import {Delete, UploadFile} from "@mui/icons-material";
import {useMsal} from "@azure/msal-react";
import {ApiHelper as api, notify} from '../../data/helpers'

interface IProps {
  open: boolean
  onClose: (cancel: boolean, newPieces: TestPiece[]) => any
  testKey: string
  insertIndex: number
}

const UploadRecordingDialog = ({open, onClose, testKey, insertIndex}: IProps) => {

  // Authentication
  const {instance} = useMsal()

  // State
  const [importReady, setImportReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedData, setUploadedData] = useState<{ title: string, steps: any[] }>();

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check for file
    if (event.target.files && event.target.files.length > 0) {
      // Set loading
      setLoading(true);

      // Create a file reader
      const fileReader = new FileReader();

      // Setup onload event
      fileReader.onload = (e) => {
        if(e.target) {
          try{
            const data = JSON.parse(e.target.result as string);
            setUploadedData(data);
            setImportReady(data.title && Array.isArray(data.steps));
          } catch(e) {}
        }

        // Set not loading anymore
        setLoading(false);
      }

      // Read file
      fileReader.readAsText(event.target.files[0]);
    }
  }

  const onImport = () => {
    // Import to api
    if(importReady && uploadedData) {
      api.importRecording(testKey, {insert_index: insertIndex, upload: uploadedData}, instance).then((data: TestPiece[]) => {
        // Reset State
        setImportReady(false);
        setLoading(false);
        setUploadedData(undefined);

        // Close dialog
        onClose(false, data);
      });
    }
  }

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Import Recording
      </DialogTitle>
      <DialogContent>
        { !loading && !importReady &&
          <TextField type={'file'} inputProps={{accept: ".json"}} fullWidth onChange={onFileSelect} />
        }
        {loading &&
          <LinearProgress />
        }
        {!loading && uploadedData && uploadedData.title && Array.isArray(uploadedData.steps) &&
          <Typography>Importing "{uploadedData.title}" with {uploadedData.steps.length} steps</Typography>
        }
        {!loading && uploadedData && (!uploadedData.title || !Array.isArray(uploadedData.steps)) &&
          <Typography>Invalid upload. Please select a proper recording JSON file</Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true, [])} startIcon={<Delete />} color={"error"}>Cancel</Button>
        <Button disabled={!importReady} startIcon={<UploadFile />} onClick={onImport}>Import</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadRecordingDialog;
