import {TestPieceType} from "./TestPieceType";

export interface TestPiece {
  test_key: string
  test_piece_key: string
  piece_key: number
  order: number
  comment: string
  locator: string
  locator_type: number
  requires_locator: boolean
  data: any[]
  variable_data: boolean
  on_fail_execute: string
  log_result: boolean
  expect_failure: boolean
  on_fail_variable_action: string
  on_fail_variable: string,
  on_fail_variable_value: string
}

// Validate custom data
export const validateTestPiece = (piece: TestPiece, pieceKeys?: TestPieceType[]): TestPiece => {
  if(!piece.data || (piece.data && !Array.isArray(piece.data))) piece.data = [''];
  const len = piece.data.length;

  /* Validate/Fix some fields */
  // Fix string boolean values
  piece.log_result = piece.log_result && (piece.log_result === true || piece.log_result === 'true');
  piece.expect_failure = piece.expect_failure && (piece.expect_failure === true || piece.expect_failure === 'true');
  piece.variable_data = piece.variable_data && (piece.variable_data === true || piece.variable_data === 'true');

  // If piece doesn't support VD, set it to false
  if(pieceKeys && pieceKeys[piece.piece_key] && !pieceKeys[piece.piece_key].supports_vd && piece.variable_data) {
    piece.variable_data = false;
  }

  /* Validate Custom Data */
  // Piece 22 is variable data, must have 3 fields
  if(len < 3 && piece.piece_key === 22) {
    piece.data = ['', '', ''];
  // Calculate # of fields required based on field names
  } else if (
    pieceKeys &&
    pieceKeys[piece.piece_key] &&
    Array.isArray(pieceKeys[piece.piece_key].custom_data_labels) &&
    len < pieceKeys[piece.piece_key].custom_data_labels.length
  ) {
    piece.data = new Array<string>(pieceKeys[piece.piece_key].custom_data_labels.length);
    for(let i = 0; i < piece.data.length; i++) piece.data[i] = "";
  // Piece doesn't support variable data, only add one field
  } else if (
    pieceKeys &&
    pieceKeys[piece.piece_key] &&
    !pieceKeys[piece.piece_key].supports_vd &&
    len > 1
  ) {
    piece.data = [''];
  // Piece supports variable data, but it's not enabled
  } else if (
    pieceKeys &&
    pieceKeys[piece.piece_key] &&
    pieceKeys[piece.piece_key].supports_vd &&
    pieceKeys[piece.piece_key].custom_data_labels &&
    !piece.variable_data &&
    len > pieceKeys[piece.piece_key].custom_data_labels.length
  ) {
    piece.data = new Array<string>(pieceKeys[piece.piece_key].custom_data_labels.length);
    for(let i = 0; i < piece.data.length; i++) piece.data[i] = "";
  } else if (len < 1) {
    piece.data = [''];
  }

  return piece;
}
