import {Grid, IconButton, Tooltip} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import React, {ReactChild} from "react";


interface IProps {
  add: (i: number) => void
  remove: (i: number) => void
  field: ReactChild
  index: number
  disabled?: boolean
  canRemove?: boolean
  supportsVd?: boolean
}

const CustomDataWrapper = ({add, remove, field, index, disabled, canRemove, supportsVd}: IProps) => {
  return (
    <Grid container sx={{my: .5}} spacing={2}>
      <Grid item xs={supportsVd ? 10 : 12}>
        {/* Custom Data Input */}
        {field}
      </Grid>

      {supportsVd &&
        <>
          {/* Add button */}
          <Grid item xs={1}>
            <Tooltip title={"Add Data"}>
              <IconButton
                onClick={() => add(index + 1)}
                disabled={disabled}
                size="large"
              >
                <AddCircle color={"primary"}/>
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Remove Button */}
          <Grid item xs={1}>
            {!disabled && canRemove &&
              <Tooltip title={"Remove Data"}>
                <IconButton
                  onClick={() => remove(index)}
                  size="large"
                >
                  <RemoveCircle color={"error"}/>
                </IconButton>
              </Tooltip>
            }
            {(disabled || !canRemove) &&
              <IconButton
                disabled
                size="large"
              >
                <RemoveCircle color={"disabled"}/>
              </IconButton>
            }
          </Grid>
        </>
      }
    </Grid>
  )
}

export default CustomDataWrapper;
