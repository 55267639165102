import io from "socket.io-client";
import {IPublicClientApplication} from "@azure/msal-browser";
import {customAquireToken} from "./ApiHelper";
import StartedTest from "../../models/StartedTest";
import RunningLog from "../../models/RunningLog";

type Listener<t> = (v: t) => any
type ListenerGroup<t> = {[key: string]: Listener<t>};

export default class WebsocketHelper {
  private public_url = process.env.PUBLIC_URL;
  private host = (this.public_url && this.public_url.trim().length > 0)? this.public_url : 'http://localhost:8080';

  private socket: SocketIOClient.Socket | undefined;

  private logListeners: ListenerGroup<StartedTest> = {};
  private logPieceListeners: ListenerGroup<RunningLog> = {};
  private logCompleteListeners: ListenerGroup<any> = {};

  public init(instance: IPublicClientApplication) {
    // Connect websocket
    if (!this.socket) {
      this.connect(instance);
    } else {
      this.socket.close();
      this.connect(instance);
    }
  }

  private async connect(instance: IPublicClientApplication) {
    const token = await customAquireToken(instance);
    const fullToken = 'Bearer ' + token;
    this.socket = io(this.host,{
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: fullToken,
          }
        }
      }
    });
    // Register events
    this.socket.on("test_complete", (sockData: any) => this.emitToGroup(this.logCompleteListeners, JSON.parse(sockData)));
    this.socket.on("new_log_piece", (sockData: any) => this.emitToGroup(this.logPieceListeners, JSON.parse(sockData)));
    this.socket.on("new_log", (sockData: any) => this.emitToGroup(this.logListeners, JSON.parse(sockData)));
    // Connect
    this.socket.connect();
  }

  /**
   * Emit a payload to a group of callback functions
   * @param group Group of callbacks
   * @param payload Payload to emit
   * @private
   */
  private emitToGroup<t>(group: ListenerGroup<t>, payload: t) {
    for(const key in group) {
      group[key](payload);
    }
  }

  /**
   * Register a key to a group
   * @param group group to register to
   * @param key key to register under
   * @param cb callback function
   * @private
   */
  private registerToGroup<t>(group: ListenerGroup<t>, key: string, cb: Listener<t>) {
    group[key] = cb;
  }

  /**
   * Remove a CB by a key
   * @param group group to remove from
   * @param key key to remove
   * @private
   */
  private deregisterFromGroup<t>(group: ListenerGroup<t>, key: string) {
    if(typeof group[key] !== "undefined") delete group[key];
  }

  public registerNewLog = (key: string, cb: Listener<StartedTest>) => this.registerToGroup(this.logListeners, key, cb);
  public deregisterNewLog = (key: string) => this.deregisterFromGroup(this.logListeners, key);

  public registerNewLogPiece = (key: string, cb: Listener<RunningLog>) => this.registerToGroup(this.logPieceListeners, key, cb);
  public deregisterNewLogPiece = (key: string) => this.deregisterFromGroup(this.logPieceListeners, key);

  public registerComplete = (key: string, cb: Listener<any>) => this.registerToGroup(this.logCompleteListeners, key, cb);
  public deregisterComplete = (key: string) => this.deregisterFromGroup(this.logCompleteListeners, key);

}
