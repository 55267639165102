import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {ApiHelper as api, notify} from '../../data/helpers'
import {PageType, ToastStatus} from '../../core/Enums'
import TestPropertiesTab from '../../components/EditTest';
import {
  Assignment, CloudDone,
  Edit,
  Warning
} from '@mui/icons-material';
import {
  AppBar, Box,
  Paper,
  Tab,
  Tabs
} from '@mui/material';
import {useHistory, useLocation, useParams} from 'react-router';
import {useMsal} from '@azure/msal-react';
import {TestPieceType} from "../../models/TestPieceType";
import {Test} from "../../models/Test";
import {Log} from '../../models/Log';
import {LogPiece} from "../../models/LogPiece";
import TestLogTab from "../../components/TestLogTab";
import LogSelector from "../../components/LogSelector";
import TestResultTab from "../../components/TestResultTab";
import TestDesignerTab from "../../components/TestDesignerTab";
import {calculateTestResultsFromLogs} from "../../data/helpers/FunctionHelpers";
import RunTestFAB from "../../components/RunTestFAB";
import {useSocket} from "../../data/hooks/useSocket";
import RunningLog from "../../models/RunningLog";
import StartedTest from "../../models/StartedTest";
import FinishedTest from "../../models/FinishedTest";

interface IProps {
  setTitle: (title: string) => void
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TestDesigner = (props: IProps) => {

  // Hooks
  const history = useHistory();
  const query = useQuery();
  const {test_key}: any = useParams();
  const {instance} = useMsal()
  const socket = useSocket();

  // State
  const [testKey, setTestKey] = useState<string>(test_key);
  const [test, setTest] = useState<Test>({} as Test);
  const [groups, setGroups] = useState<Test[]>([]);
  const [pageType] = useState<PageType>((document.location.pathname.indexOf('/tests/') > -1) ? PageType.Test : PageType.Group);
  const [pieceTypes, setPieceTypes] = useState<TestPieceType[]>([]);
  const [tab, setTab] = useState<number>(0);
  const [testLogs, setTestLogs] = useState<Log[]>([]);
  const [logPieces, setLogPieces] = useState<LogPiece[]>([]);
  const [selectedLog, setSelectedLog] = useState<string>('');
  const [testResults, setTestResults] = useState<LogPiece[]>([]);
  const [upcomingTestResults, setUpcomingTestResults] = useState<LogPiece[]>([]);

  // Refs
  const testLogsRef = useRef(testLogs);
  const logPiecesRef = useRef(logPieces);
  const selectedLogRef = useRef(selectedLog);
  const testResultsRef = useRef(testResults);
  const upcomingTestResultsRef = useRef(upcomingTestResults);

  useEffect(() => {
    const currentLog = query.get('log_key')

    // Get Test Key
    const currentTest = test_key;

    // Set Test Key
    setTestKey(currentTest);

    // Determine tab
    if (window.location.pathname.endsWith('/design')) {
      setTab(0);
    } else if (window.location.pathname.endsWith('/logs')) {
      setTab(1);
    } else if (window.location.pathname.endsWith('/results')) {
      setTab(2);
    } else if (window.location.pathname.endsWith('/properties')) {
      setTab(3);
    } else {
      pushLocation(`/${pageType.toLowerCase()}s/${currentTest}/design`);
    }

    // Get Test Properties
    api.getTestProperties(currentTest, instance).then((data) => {
      if (!data || (data && Array.isArray(data) && data.length === 0)) {
        pushLocation('/error');
      } else if (data && Array.isArray(data) && data.length > 0) {
        setTest(data[0]);
      }
    }).catch((err: any) => {
      notify('Failed to test pieces: ' + err.message, ToastStatus.Danger)
    });

    // Get all piece types
    api.getAllTypes(instance).then((data) => { // Get Piece Types
      if (data && Array.isArray(data) && data.length > 0) setPieceTypes(data);
    }).catch((err: any) => {
      notify('Failed to fetch piece types: ' + err.message, ToastStatus.Danger)
    });

    // Get All Groups
    api.getAllGroups(instance).then((data) => {
      if (data && Array.isArray(data) && data.length > 0) setGroups(data);
    }).catch((err: any) => {
      notify('Failed to fetch groups: ' + err.message, ToastStatus.Danger)
    });

    // Get All Logs
    api.getAllTestLogs(currentTest, instance).then((data) => {
      let newSelectedLog = '';
      // Make sure we have logs
      if (data && Array.isArray(data) && data.length > 0) {
        // If no log is set in the URL
        if (!currentLog) {
          // Default to the most recent log
          newSelectedLog = data[0].log_key;
        } else {
          // If there is a log set in the URL, lets make sure actually exists
          let found = false;
          for (const l of data) {
            if (l.log_key === currentLog) { // see if this is the log
              newSelectedLog = currentLog;
              found = true;
              break;
            }
          }

          // If we didn't find the log
          if (!found) {
            if (tab === 1) {
              pushLocation(`/${pageType.toLowerCase()}s/${currentTest}/logs`)
            } else if (tab === 2) {
              pushLocation(`/${pageType.toLowerCase()}s/${currentTest}/results`)
            }
            newSelectedLog = data[0].log_key;
          }
        }
      } else if (data && Array.isArray(data) && data.length === 0 && currentLog) {
        // If we have no logs, but the key is defined in the query
        if (tab === 1) {
          pushLocation(`/${pageType.toLowerCase()}s/${currentTest}/logs`)
        } else if (tab === 2) {
          pushLocation(`/${pageType.toLowerCase()}s/${currentTest}/results`)
        }
      }
      // Update State
      if (data && Array.isArray(data)) {
        updateTestLogs([...data]);
        updateSelectedLog(newSelectedLog);
      }
      return api.getAllTestLogPieces(newSelectedLog, instance);
    }).then((data) => {
      if (data && Array.isArray(data) && data.length > 0) {
        const newTestResults = calculateTestResultsFromLogs(data);
        updateLogPieces(data);
        updateTestResults(newTestResults);
      }
    }).catch((err: any) => {
      notify('Failed to get logs: ' + err.message, ToastStatus.Danger)
    });

    // Init Socket
    initSocket();
  }, []) // eslint-disable-line

  const initSocket = () => {
    // On New Log
    socket.registerNewLog("test-designer", (payload: StartedTest) => {
      if (payload.test_key !== testKey) return;
      const newData = payload.new_data;
      const newTestLogs = [...testLogsRef.current]
      newData.running = true;
      newTestLogs.unshift(newData);
      updateTestLogs(newTestLogs);
      updateLogPieces([]);
      updateSelectedLog(newData.log_key);
      if (upcomingTestResults) updateTestResults([...upcomingTestResultsRef.current]);
      else updateTestResults([]);
      if (tab === 1) {
        pushLocation(`/${pageType.toLowerCase()}s/${testKey}/logs`)
      } else if (tab === 2) {
        pushLocation(`/${pageType.toLowerCase()}s/${testKey}/results`)
      }
      notify('New log session available, view it in the log tab!', ToastStatus.Info)
    });

    // On New Log Piece
    socket.registerNewLogPiece("test-designer", (payload: RunningLog) => {
      // If log we received is for the currently selected log
      if (selectedLogRef.current === payload.log_key) {
        const newTestResults = [...testResultsRef.current];
        const newLogPieces = [...logPiecesRef.current];
        const newData = payload.new_data
        newLogPieces.push(newData);

        // Update Test Result array if we're supposed to log this event
        if (newData.piece.log_result === true) {
          if (newTestResults.length < 1) { // If we, for some reason, have no pre-calculated test results
            newTestResults.push(newData);
          } else {
            for (let i = 0; i < newTestResults.length; i++) {
              if (!newTestResults[i].incomplete) continue; // If complete, skip
              if (newTestResults[i].piece.test_piece_key === newData.piece.test_piece_key) newTestResults[i] = newData; // if incomplete, replace
            }
          }
        }
        updateTestResults(newTestResults);
        updateLogPieces(newLogPieces)
      }
    });

    // On Test Complete
    socket.registerComplete("test-designer", (payload: FinishedTest) => {
      // Find log
      const index = testLogsRef.current.findIndex(t => t.log_key === payload.log_key);
      // If we found the log, we need to update the "pass" state
      if(index > -1) {
        const copy = [...testLogsRef.current];
        copy[index].pass = payload.pass;
        copy[index].running = false;
        updateTestLogs(copy);
      }
      // If log we received is for the currently selected log
      if (selectedLogRef.current === payload.log_key) {
        const str = payload.pass ? 'Test Complete: Passed' : `Test Complete: Failed (${payload.failed_logs.length} issue${payload.failed_logs.length !== 1 ? 's' : ''})`
        notify(str, payload.pass ? ToastStatus.Success : ToastStatus.Danger);
      }
    })
  }

  /**----------- State/Ref Update Functions ----------**/

  const updateTestLogs = (newState: Log[]) => {
    testLogsRef.current = newState;
    setTestLogs(newState);
  }
  const updateLogPieces = (newState: LogPiece[]) => {
    logPiecesRef.current = newState;
    setLogPieces(newState);
  }
  const updateSelectedLog = (newState: string) => {
    selectedLogRef.current = newState;
    setSelectedLog(newState);
  }
  const updateTestResults = (newState: LogPiece[]) => {
    testResultsRef.current = newState;
    setTestResults(newState);
  }
  const updateUpcomingTestResults = (newState: LogPiece[]) => {
    upcomingTestResultsRef.current = newState;
    setUpcomingTestResults(newState);
  }


  const pushLocation = (location: string) => { // Make Sure we don't push the same location many times
    if ((window.location.pathname + window.location.search) !== location) {
      history.push(location);
    }
  }

  /**----------Functions to do with logs----------**/

    // Update state, and get logs from selection
  const getLogPieces = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;
      if (value !== selectedLog) updateSelectedLog(value);
      if (testLogs && testLogs.length > 0 && value !== testLogs[0].log_key) {
        if (tab === 1) {
          pushLocation(`/${pageType.toLowerCase()}s/${test_key}/logs?log_key=${value}`)
        } else if (tab === 2) {
          pushLocation(`/${pageType.toLowerCase()}s/${test_key}/results?log_key=${value}`)
        }
      } else {
        if (tab === 1) {
          pushLocation(`/${pageType.toLowerCase()}s/${test_key}/logs`)
        } else if (tab === 2) {
          pushLocation(`/${pageType.toLowerCase()}s/${test_key}/results`)
        }
      }
      api.getAllTestLogPieces(value, instance).then((data) => {
        if (data && data.length > 0) {
          const newTestResults = calculateTestResultsFromLogs(data)
          updateLogPieces(data);
          updateTestResults(newTestResults);
        }
      })

    }

  // Update selected tab
  const updateTab = (newTab: number) => {
    const pages = ['design', 'logs', 'results', 'properties']
    if (testLogs && testLogs.length > 0 && selectedLog !== testLogs[0].log_key && (newTab === 1 || newTab === 2)) {
      if (newTab === 1) {
        pushLocation(`/${pageType.toLowerCase()}s/${testKey}/logs?log_key=${selectedLog}`)
      } else if (newTab === 2) {
        pushLocation(`/${pageType.toLowerCase()}s/${testKey}/results?log_key=${selectedLog}`)
      }
    } else {
      pushLocation(`/${pageType.toLowerCase()}s/${testKey}/${pages[newTab]}`)
    }
    setTab(newTab);
  }

  return (
    <>
      {/* Tab Selector */}
      <AppBar position={'static'}>
        <Tabs value={tab} onChange={(e: any, v: number) => updateTab(v)} variant={'fullWidth'}
              centered>
          <Tab label={'Test Designer'} icon={<Assignment/>}/>
          <Tab label={'Logs'} icon={<Warning/>}/>
          <Tab label={'Test Results'} icon={<CloudDone/>}/>
          <Tab label={'Edit Test Properties'} icon={<Edit/>}/>
        </Tabs>
      </AppBar>

      {/* Tabs */}
      <Paper sx={{p: 2}}>
        {tab === 0 && testKey && testKey.length > 0 &&
          <Box>
            <TestDesignerTab testKey={testKey} pageType={pageType} pieceTypes={pieceTypes} groups={groups}/>
          </Box>
        }
        {tab === 1 &&
          <Box>
            <LogSelector testLogs={testLogs} selectedLog={selectedLog} onChange={getLogPieces}/>
            <TestLogTab logPieces={logPieces} pieceTypes={pieceTypes} groups={groups}/>
          </Box>
        }
        {tab === 2 &&
          <Box>
            <LogSelector testLogs={testLogs} selectedLog={selectedLog} onChange={getLogPieces}/>
            <TestResultTab testResults={testResults} pieceTypes={pieceTypes} groups={groups} pageType={pageType}/>
          </Box>
        }
        {tab === 3 &&
          <Box>
            <TestPropertiesTab {...props} test={test} type={pageType} key={test.test_key}/>
          </Box>
        }
      </Paper>

      {/* FAB (Floating Action Button) */}
      <RunTestFAB testKey={testKey} updateUpcomingTestResults={updateUpcomingTestResults}/>
    </>
  );

}


export default TestDesigner;
