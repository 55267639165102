import React, {useEffect, useState} from "react";
import {ApiHelper as api, notify} from "../../data/helpers"
import {PageType, ToastStatus} from "../../core/Enums";
import {Box, Dialog, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";
import {useHistory} from "react-router";
import {useMsal} from "@azure/msal-react";
import {Test} from "../../models/Test";
import EditTest from "../../components/EditTest";
import {Add} from "@mui/icons-material";
import {Fab} from "react-tiny-fab";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid';


interface Props {
  setTitle: (title: string) => void
}

const TestsGroups = ({setTitle}: Props) => {

  // Hooks
  const history = useHistory();
  const {instance} = useMsal();
  const theme = useTheme();

  // State
  const [pageType] = useState((document.location.pathname.indexOf('/tests') > -1) ? PageType.Test : PageType.Group)
  const [tests, setTests] = useState<Test[]>([])
  const [modalOpen, setModalOpen] = useState(false)

  // Data table map
  const [columns, setColumns] = useState<GridColDef[]>([
    {field: 'name', headerName: 'Test Name', flex: 2},
    {field: 'description', headerName: "Description", flex: 2},
    {
      field: 'date_modified',
      headerName: 'Date Modified',
      type: 'date',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Test>) =>
        params.row && params.row.date_modified ? new Date(params.row.date_modified).toLocaleString() : "N/A"
    },
    {
      field: 'date_created',
      headerName: 'Date Created',
      type: 'date',
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Test>) =>
        params.row && params.row.date_created ? new Date(params.row.date_created).toLocaleString() : "N/A"
    }
  ]);

  useEffect(() => {
    // Get Tests
    const getPromise = (pageType === PageType.Group) ? api.getAllGroups(instance) : api.getAllTests(instance);
    getPromise.then((data) => {
      setTests(data.sort((a, b) => (a.name > b.name ? 1 : -1)))
    }).catch((err: any) => {
      notify(`Failed to fetch ${pageType.toLowerCase()}s: ${err.message}`, ToastStatus.Danger);
    })
    setTitle(pageType);

    // Tests page gets extra column
    if (pageType === PageType.Test) {
      const copy = [...columns];
      copy.push(
        {
          field: 'last_run_status',
          headerName: 'Last Run Status',
          flex: 1,
          renderCell: (params: GridRenderCellParams<Test>) =>
            <Typography variant={"subtitle2"} color={getTestStatusClass(params.row)}>
              {params.row.last_run_status.toUpperCase()}
            </Typography>
        }
      );

      setColumns(copy);
    }
    // eslint-disable-next-line
  }, [])

  // Handle clicking on a row
  const handleClick = (test: Test) => {
    setTitle('Editing ' + test.name + ' ' + pageType);
    history.push(`/${pageType.toLowerCase()}s/${test.test_key}`);
  }

  // Toggle modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  // Get test status class
  const getTestStatusClass = (test: Test) => {
    switch (test.last_run_status) {
      case 'success':
        return 'success.main';
      case 'fail':
        return 'error.main';
      default:
        return undefined;
    }
  }

  return (
    <Box sx={{p: 1}}>
      <DataGrid
        rows={tests}
        getRowId={(t) => t.test_key}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        onRowClick={(params: GridRowParams<Test>) => handleClick(params.row)}
        autoHeight
      />


      {/* Create New FAB */}
      {!modalOpen &&
        <Fab
          mainButtonStyles={{backgroundColor: theme.palette.primary.main}}
          icon={<Add/>}
          event={'hover'}
          text={`Create New ${pageType}`}
          onClick={() => setModalOpen(true)}
        />
      }

      {/* Test/Group Creation Modal */}
      <Dialog open={modalOpen} onClose={() => toggleModal()} maxWidth={"lg"} fullWidth>
        <DialogTitle>
          Create New {pageType}
        </DialogTitle>
        <DialogContent dividers>
          <EditTest setTitle={setTitle} type={pageType}/>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default TestsGroups;
