import {AccountInfo, AuthenticationResult, IPublicClientApplication} from '@azure/msal-browser';
import { loginRequest } from '../../core/MsalConfig';
import {ToastStatus} from "../../core/Enums";
import {notify} from "./index";
import { TestPieceType } from '../../models/TestPieceType';
import {DuplicatedTest, NewTest, Test} from '../../models/Test';
import {TestPiece} from "../../models/TestPiece";
import {Log} from "../../models/Log";
import {LogPiece} from "../../models/LogPiece";
import {ExecutedTest} from "../../models/ExecutedTest";
import {Browser} from "../../models/Browser";
import {Screenshot} from "../../models/Screenshot";

const baseurl = ((process.env.PUBLIC_URL && process.env.PUBLIC_URL !== '') ? process.env.PUBLIC_URL : 'http://localhost:8080') + '/api';

async function defaultOptions(instance: IPublicClientApplication): Promise<any> {
  return customAquireToken(instance).then((token: any) => {
    return {
      headers: {'Content-Type': 'application/json', 'authorization': `Bearer ${token}`},
    }
  })
}

export function customAquireToken(instance: IPublicClientApplication) {
  let account: AccountInfo = {environment: '', homeAccountId: '', localAccountId: '', tenantId: '', username: ''};

  const inst = instance.getActiveAccount();
  if (inst) account = inst;

  return instance.acquireTokenSilent({
    ...loginRequest,
    account: account
  }).then((resp: AuthenticationResult) => {
    return resp.idToken;
  }).catch((err: any) => {
    notify(`Failed to get Azure Token: ${err.message}`, ToastStatus.Danger);
  })
}

const onSuccess = function<T> (res: Response): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    if (res.status === 401) {
      reject('Login Error')
    } else {
      resolve(res.json() as Promise<T>);
    }
  })
}

export const getJson = function<T> (route: string, instance: IPublicClientApplication): Promise<T> {
  return defaultOptions(instance).then((opts => fetch(baseurl + route, opts).then(res => onSuccess<T>(res))));
}

const postJson = function<T> (route: string, payload: any, instance: IPublicClientApplication) {
  const body = (payload) ? {body: JSON.stringify(payload)} : {}
  return defaultOptions(instance).then((opts) => fetch(baseurl + route, {...body, ...opts, method: 'POST'}).then(res => onSuccess<T>(res)));
}

const deleteJson = function (route: string, payload: any, instance: IPublicClientApplication) {
  const body = (payload) ? {body: JSON.stringify(payload)} : {}
  return defaultOptions(instance).then((opts) => fetch(baseurl + route, {...body, ...opts, method: 'DELETE', }).then(res => onSuccess(res)));
}

export const getAllTests = function (instance: IPublicClientApplication): Promise<Test[]> {
  return getJson<Test[]>('/testing/all/tests', instance);
}

export const getAllGroups = function (instance: IPublicClientApplication): Promise<Test[]> {
  return getJson<Test[]>('/testing/all/groups', instance);
}

export const getAllTypes = function (instance: IPublicClientApplication): Promise<TestPieceType[]> {
  return getJson<TestPieceType[]>('/pieces/types', instance);
}

export const getTestProperties = function (test_key: string, instance: IPublicClientApplication): Promise<Test[]> {
  return getJson<Test[]>('/testing/' + test_key, instance);
}

export const getAllTestPieces = function (test_key: string, instance: IPublicClientApplication): Promise<TestPiece[]> {
  return getJson<TestPiece[]>('/pieces/' + test_key, instance);
}

export const getAllTestLogs = function (test_key: string, instance: IPublicClientApplication): Promise<Log[]> {
  return getJson<Log[]>('/logs/' + test_key, instance);
}

export const getAllTestLogPieces = function (test_key: string, instance: IPublicClientApplication): Promise<LogPiece[]> {
  return getJson<LogPiece[]>('/logs/' + test_key + '/pieces', instance);
}

export const executeTest = function (test_key: string, instance: IPublicClientApplication): Promise<ExecutedTest> {
  return getJson<ExecutedTest>('/testing/' + test_key + '/execute', instance);
}

export const getVersion = function (instance: IPublicClientApplication) {
  return getJson('/version', instance);
}

export const getBrowsers = function (instance: IPublicClientApplication): Promise<Browser[]> {
  return getJson<Browser[]>('/bstack/browsers.json', instance);
}

export const getScreenshot = function (instance: IPublicClientApplication, logKey: string): Promise<Screenshot[]> {
  return getJson<Screenshot[]>('/logs/screenshots/' + logKey, instance);
}

export const postPieces = function (pieces: any, instance: IPublicClientApplication) {
  return postJson('/pieces', pieces, instance);
}

export const postTests = function (tests: any, instance: IPublicClientApplication): Promise<NewTest[]> {
  return postJson<NewTest[]>('/testing', tests, instance);
}

export const deletePiece = function (piece_key: string, instance: IPublicClientApplication) {
  return deleteJson('/pieces/' + piece_key, undefined, instance);
}

export const deleteTest = function (test_key: string, instance: IPublicClientApplication) {
  return deleteJson('/testing/' + test_key, undefined, instance);
}

export const logout = function (instance: IPublicClientApplication) {
  return postJson('/users/logout', undefined, instance)
}

export const login = function (creds: any, instance: IPublicClientApplication) {
  return postJson('/users/login', creds, instance);
}

export const importRecording = function (testKey: string, recording: any, instance: IPublicClientApplication) {
  return postJson<TestPiece[]>(`/pieces/${testKey}/import`, recording, instance);
}

export const duplicateTest = function (testKey: string, instance: IPublicClientApplication): Promise<DuplicatedTest[]> {
  return postJson<DuplicatedTest[]>(`/testing/${testKey}/duplicate`, undefined, instance);
}
