import React, {useEffect} from "react";
import { createContext, ReactChild } from "react";
import WebsocketHelper from "../helpers/WebsocketHelper"
import {useIsAuthenticated, useMsal} from "@azure/msal-react";

const ws = new WebsocketHelper();

export const SocketContext = createContext(ws);

interface ISocketProvider {
  children: ReactChild;
}

export const SocketProvider = (props: ISocketProvider) => {
  const {instance} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if(isAuthenticated) ws.init(instance);
  }, [isAuthenticated]) // eslint-disable-line

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  );
}
