import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {createTheme, ThemeProvider, StyledEngineProvider, CssBaseline} from "@mui/material";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./core/MsalConfig";
import {MsalProvider} from "@azure/msal-react";
import {SocketProvider} from "./data/providers/SocketProvider";


const myTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: '#f3744b',
      main: '#EF5B29',
      dark: '#EF5B29',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4b98d7',
      main: '#1b75ba',
      dark: '#1b75ba',
      contrastText: '#000',
    }
  },
});

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
        <MsalProvider instance={pca}>
          <SocketProvider>
            <App />
          </SocketProvider>
        </MsalProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
