// This is supposed to be Localhost. We're connecting to an app running locally
import {IPublicClientApplication} from '@azure/msal-browser';
import {customAquireToken} from './ApiHelper';

const baseurl = 'http://localhost:5954';

async function defaultOptions(instance: IPublicClientApplication): Promise<any> {
  return customAquireToken(instance).then((token: any) => {
    return {
      headers: {'Content-Type': 'application/json', 'authorization': `Bearer ${token}`},
    }
  })
}

export const startTest = function (test_key: string, instance: IPublicClientApplication) {
  // @ts-ignore
  return defaultOptions(instance).then((defaults) => fetch(baseurl + '/run/' + test_key, defaults).then(res => res.json()));
}



