import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import React, {useState} from "react";
import {LogLevel} from "../../core/Enums";
import {TestPiece} from "../../models/TestPiece";
import {LogPiece} from "../../models/LogPiece";
import TestPieceModal from "../TestPieceModal/TestPieceModal";
import {TestPieceType} from "../../models/TestPieceType";
import {Test} from "../../models/Test";

interface IProps {
  logPieces: LogPiece[],
  pieceTypes: TestPieceType[]
  groups: Test[]
}

const TestLogTab = ({logPieces, pieceTypes, groups}: IProps) => {

  const [modalData, setModalData] = useState<TestPiece>({} as TestPiece);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalLocked, setModalLocked] = useState<boolean>(false);
  const [openLogId, setOpenLogId] = useState<string>();

  // Handle a click on a log's row (open modal, load data, etc)
  const rowClickLog = (piece: any, hasScreenshot: string) => {
    setModalData(piece);
    setOpenLogId(hasScreenshot);
    setModalOpen(true);
    setModalLocked(true);
  }

  const renderAllLogs = () => {
    if (logPieces) {
      return (
        logPieces.map((l: any, i: number) => {
          const startDate = new Date(l.date);
          const dateString = startDate.toDateString() + ' at ' + startDate.getHours() + ':' + startDate.getMinutes() + ':' + startDate.getSeconds();
          if (l.level === LogLevel.Error || l.level === LogLevel.Info) {
            return ( // Only Render Info and Error messages here
              <TableRow hover key={i} style={{cursor: 'pointer'}} onClick={() => {
                if (l.piece) rowClickLog(l.piece, (l.screenshot_available) ? l.log_piece_key : undefined)
              }}>
                {(l.level === LogLevel.Error) ?
                  <TableCell><Typography sx={{color: "error.main"}}>Error</Typography></TableCell>
                  :
                  <TableCell><Typography>Info</Typography></TableCell>
                }
                <TableCell>{dateString}</TableCell>
                <TableCell>{l.message}</TableCell>
              </TableRow>
            )
          } else {
            return undefined;
          }
        })
      )
    } else {
      return;
    }
  }

  return (
    <>

      {/* Log Pieces */}
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{mb: 0}} size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell key={0} scope='col'>Type</TableCell>
              <TableCell key={1} scope='col'>Date/Time</TableCell>
              <TableCell key={2} scope='col'>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderAllLogs()}
          </TableBody>
        </Table>
      </TableContainer>


      {/* Modal */}
      <TestPieceModal key={modalData.test_piece_key} modalClose={() => setModalOpen(false)}
                      propsModalSave={() => {}}
                      open={modalOpen} pieceTypes={pieceTypes} modalLocked={modalLocked}
                      modalData={modalData} groups={groups} logKey={openLogId}
      />
    </>
  )
}

export default TestLogTab;
