import React, {useEffect, useState} from 'react';
import {ApiHelper as api, notify} from '../../data/helpers'
import {BrowserType, PageType, ToastStatus} from "../../core/Enums";
import {Button, Divider, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {Add, Delete, FileCopy, Save} from "@mui/icons-material";
import {useMsal} from "@azure/msal-react";
import {useHistory} from "react-router";
import {BrowserstackConfig, BrowserstackProduction, Test, TestProperties} from "../../models/Test";
import BrowserstackConfigPane from "../BrowserstackConfig";

interface IProps {
  test?: Test
  type: PageType
  setTitle: (title: string) => void
}


const EditTest = ({type, test: propsTest}: IProps) => {

  // Hooks
  const {instance} = useMsal();
  const history = useHistory();

  // State
  const [test, setTest] = useState<Test>(propsTest ?? {test_properties: {browserstack_configs: [{} as BrowserstackConfig]} as TestProperties} as Test);
  const [isGroup, setIsGroup] = useState(document.location.pathname.indexOf('/groups') > -1);

  // Constants
  const pathName = document.location.pathname;
  const newTest = (pathName === '/tests' || pathName === '/groups');

  useEffect(() => {
    if (newTest) setTest({...test, test_key: ''});
    // Only get API data if we actually have a test/group key
    if (test) {
      // Make sure configs aren't empty
      if (!test.test_properties) test.test_properties = {
        browserstack_configs: [{} as BrowserstackConfig],
        local_browser: BrowserType.Chrome,
        timeout_seconds: 60
      };
      if (!test.test_properties.browserstack_configs || test.test_properties.browserstack_configs.length < 1) test.test_properties.browserstack_configs = [{} as BrowserstackConfig];
    }
    // eslint-disable-next-line
  }, [])


  const saveTestConfig = () => {
    const date = new Date();
    const testCopy = {...test};
    if (newTest) {
      // New Test
      testCopy.date_created = date.toISOString();
      testCopy.date_modified = date.toISOString();
      setIsGroup(type === PageType.Group)
    } else {
      testCopy.date_modified = date.toISOString();
    }

    const toPost = {
      test_key: (newTest) ? undefined : test?.test_key,
      name: test.name,
      description: test.description,
      test_properties: test.test_properties,
      date_created: test.date_created,
      date_modified: test.date_modified,
      test_data: {}, // testData,
      is_group: isGroup
    }

    // Format browserstack configs
    for (let i = 0; i < test.test_properties.browserstack_configs.length; i++) {
      const conf = test.test_properties.browserstack_configs[i];
      const prod = {} as BrowserstackProduction;
      if (!conf.platform || !conf.osVersion || !conf.browser || !conf.version) {
        notify(`Browserstack Config #${i + 1} is invalid: ${!conf.platform ? 'Missing Platform' : !conf.osVersion ? 'Missing OS Version' : !conf.browser ? 'Missing Browser' : !conf.version ? 'Missing Version' : 'Unknown'}`, ToastStatus.Warning);
        return;
      }
      if ((conf.platform === 'Windows' || conf.platform === "OS X") && conf.resolution) {
        prod.os = conf.platform;
        prod.os_version = conf.osVersion;
        prod.browserName = conf.browser;
        prod.browser_version = conf.version;
      } else if (conf.platform === 'android' || conf.platform === "ios") {
        prod.device = conf.platform;
        prod.os_version = conf.osVersion;
        prod.browserName = conf.browser;
        prod.realMobile = true;
      } else if ((conf.platform === 'Windows' || conf.platform === "OS X") && !conf.resolution) {
        notify(`Browserstack Config #${i + 1} is invalid: Missing Resolution`, ToastStatus.Warning);
        return;
      }
      if (!toPost.test_properties.browserstack_configs) toPost.test_properties.browserstack_configs = [...test.test_properties.browserstack_configs]
      toPost.test_properties.browserstack_configs[i].prod = prod;
    }

    api.postTests([toPost], instance).then((json) => {
      if (newTest) {
        if (json.length > 0) {
          history.push('/' + type.toLowerCase() + 's/' + json[0].test_key);
        }
        notify("Successfully Created Test!", ToastStatus.Success);
      } else {
        notify("Successfully Saved Properties!", ToastStatus.Success);
      }
    }).catch((err: any) => {
      notify(`Failed to create ${isGroup ? 'group: ' : 'test: '} ${err.messsage}`, ToastStatus.Danger);
    });
  }

  const duplicateTest = () => {
    if (test.test_key && test.test_key.length > 0)
      api.duplicateTest(test.test_key, instance).then((body) => {
        history.push('/' + type.toLowerCase() + 's/' + body[0].test_key);
        notify("Successfully Duplicated Test!", ToastStatus.Success);
      }).catch((err: any) => {
        notify(`Failed to Duplicate Test: ${err.message}`, ToastStatus.Warning);
      })
  }

  const deleteTest = () => {
    if (test.test_key && test.test_key.length > 0)
      api.deleteTest(test.test_key, instance).then((data) => {
        history.push('/' + type.toLowerCase() + 's');
        notify("Successfully Deleted Test!", ToastStatus.Success);
      }).catch((err) => {
        notify("Failed to Delete Test!", ToastStatus.Warning);
      })
  }

  const updateTestProperties = (key: keyof TestProperties, prop: any) => {
    const newProperties = {...test.test_properties} as any;
    newProperties[key] = prop;
    setTest({...test, test_properties: newProperties})
  }

  const updateTest = (key: keyof Test, prop: any) => {
    const newTest = {...test} as any;
    newTest[key] = prop;
    setTest(newTest)
  }

  return (
    <>
      {/* Top Row, Name/Description */}
      <Grid container spacing={2}>
        {/* Name */}
        <Grid item lg={6} xs={12}>
          <TextField
            sx={{my: 1}}
            label={"Name"}
            variant={"outlined"}
            fullWidth
            value={test.name}
            onChange={(e) => updateTest('name', e.target.value)}
          />
        </Grid>
        {/* Description */}
        <Grid item lg={6} xs={12}>
          <TextField
            sx={{my: 1}}
            label={"Description"}
            variant={"outlined"}
            fullWidth
            value={test.description}
            onChange={(e) => updateTest('description', e.target.value)}
          />
        </Grid>
      </Grid>

      {/*Second Row Local Testing/Test Timeout */}
      <Grid container spacing={2}>
        {/* Browser Selection */}
        <Grid item lg={8} xs={8}>
          <TextField
            sx={{my: 1}}
            select
            label={"Local Testing Browser"}
            variant={"outlined"}
            fullWidth
            value={test.test_properties?.local_browser ?? ''}
            onChange={(e) => updateTestProperties('local_browser', e.target.value)}
          >
            {Object.keys(BrowserType).map((b, i) => (
              <MenuItem key={i} value={BrowserType[b]}>{b}</MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* Test Timeout */}
        <Grid item lg={4} xs={4}>
          <TextField
            sx={{my: 1}}
            label={"Test Timeout (Seconds)"}
            variant={"outlined"}
            fullWidth
            type={"number"}
            value={test.test_properties?.timeout_seconds ?? 60}
            onChange={(e) => updateTestProperties('timeout_seconds', e.target.value)}
          />
        </Grid>
      </Grid>

      <Divider sx={{mt: 4, mb: 2}}/>

      {/* Browserstack Configurations */}
      <Typography sx={{p: 2}}><b>BrowserStack Configurations:</b></Typography>
      <BrowserstackConfigPane setTest={setTest} test={test}/>

      {/* Save/Duplicate/Delete Buttons */}
      <Grid container spacing={2} direction={"row-reverse"}>
        <Grid item>
          <Button
            color={"primary"}
            startIcon={newTest ? <Add/> : <Save/>}
            onClick={() => saveTestConfig()}
          >
            {newTest ? "Create" : "Save All"}
          </Button>
        </Grid>
        {!newTest &&
          <>
            <Grid item>
              <Button
                color={"warning"}
                startIcon={<FileCopy/>}
                onClick={() => duplicateTest()}
              >
                Duplicate Test
              </Button>
            </Grid>
            <Grid item>
              <Button
                color={"error"}
                style={{float: "right"}}
                startIcon={<Delete/>}
                hidden={!test.test_key || test.test_key.length === 0}
                onClick={() => deleteTest()}
              >
                Delete Test
              </Button>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
}

export default EditTest;
