import React from 'react';
import {Switch, Route} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import ErrorPage from './pages/error';
import './styles/theme.scss';
import 'react-tiny-fab/dist/styles.css';
import Login from "./pages/login";
import {ToastContainer} from "react-toastify";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal} from "@azure/msal-react";
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";

const App = () => {


    const {accounts} = useMsal()
    const account = useAccount(accounts[0] || {})

    return (
        <>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {
                    account?.username?.endsWith('@essiumlabs.com') &&
                    <BrowserRouter>
                      <Switch>
                        <Route path="/" component={Layout}/>
                        <Route path="/error" exact component={ErrorPage}/>
                        <Route component={ErrorPage}/>
                      </Switch>
                    </BrowserRouter>
                }
                {
                    !account?.username?.endsWith('@essiumlabs.com') &&
                    <Unauthorized/>
                }
            </AuthenticatedTemplate>
            <ToastContainer/>
        </>
    );
}

export default App;
