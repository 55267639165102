import React, {useState} from "react";
import {Switch, Route, Redirect, useHistory} from "react-router";
import TestsGroups from "../../pages/tests_groups/TestsGroupsDashboard";
import s from "./Layout.module.scss";
import TestDesigner from "../../pages/test_designer/TestDesigner";
import logo from "../../images/logo.svg"
import {
  AppBar,
   Divider,
  Drawer,
  IconButton, List, ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import {
  Ballot,
  BallotOutlined,
  ChevronLeft,
  ExitToApp,
  Folder,
  FolderOutlined,
  Menu
} from "@mui/icons-material";
import ErrorPage from "../../pages/error";
import {useMsal} from "@azure/msal-react";


const Index = () => {

  const [breadCrumbTitle, setBreadCrumbTitle] = useState("Essium Testing");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  
  const history = useHistory()
  const { instance } = useMsal();

  const doLogout = () => {
    instance.logoutRedirect();
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }

  const setTitle = (title: string) => {
    setBreadCrumbTitle(title);
  }
  
  return (
    <div className={s.root}>
      {/* Top Nav Bar */}
      <AppBar position={"fixed"} className={s.appBar + ' ' + ((sidebarOpen) ? s.appBarShift : s.appBarNoShift)} >
        <Toolbar>
          <IconButton
            edge={"start"}
            hidden={sidebarOpen}
            className={s.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => toggleSidebar()}
            size="large">
            <Menu />
          </IconButton>
          <Typography variant="h6" >
            {breadCrumbTitle}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Side Drawer */}
      <Drawer anchor={"left"} variant={"permanent"} className={s.drawer + ' ' + ((sidebarOpen) ? s.drawerOpen : s.drawerClose)}
              classes={{paper: ((sidebarOpen) ? s.drawerOpen : s.drawerClose)}}
      >
        <div className={s.toolbar}>
          <div className={s.logo}><img className={s.logoStyle} src={logo} alt={"Essium Logo"} /> <span>Xenqu Tests</span> </div>
          <IconButton disabled={!sidebarOpen} onClick={() => toggleSidebar()} size="large">
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => history.push('/t')} key={"test"}>
            <ListItemIcon>
              {window.location.href.includes("/tests") ? (
                <Ballot className={s.activeIcon}/>
              ) : (
                <BallotOutlined className={s.inactiveIcon}/>
              )}
            </ListItemIcon>
            <ListItemText primary={"Tests"} />
          </ListItem>
          <ListItem button onClick={() => history.push('/g')} key={"group"}>
            <ListItemIcon>
              {window.location.href.includes("/groups") ? (
                <Folder className={s.activeIcon}/>
              ) : (
                <FolderOutlined className={s.inactiveIcon}/>
              )}
            </ListItemIcon>
            <ListItemText primary={"Groups"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key={"logout"} onClick={() => doLogout()}>
            <ListItemIcon><ExitToApp className={s.inactiveIcon}/></ListItemIcon>
            <ListItemText primary={"Logout"}  />
          </ListItem>
        </List>
      </Drawer>
      <main className={s.content}>
        <div className={s.toolbar} />
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/tests" />}/>
            <Route path="/t" exact render={() => <Redirect to="/tests" />}/>
            <Route path="/g" exact render={() => <Redirect to="/groups" />}/>
            <Route path="/tests" exact render={(props) => <TestsGroups {...props} setTitle={setTitle.bind(this)} />}/>
            <Route path="/groups" exact render={(props) => <TestsGroups {...props} setTitle={setTitle.bind(this)} />}/>
            <Route path="/tests/:test_key" render={(props) => <TestDesigner {...props} setTitle={setTitle.bind(this)}/>}/>
            <Route path="/groups/:test_key" render={(props) => <TestDesigner {...props} setTitle={setTitle.bind(this)}/>}/>
            <Route path="/" component={ErrorPage}/>
          </Switch>
      </main>
    </div>
  );
}

export default Index;
