import React from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import logo from "../../images/logo.svg";
import MicrosoftLoginButton from "../../components/MicrosoftLoginButton/MicrosoftLoginButton";
import {loginRequest} from "../../core/MsalConfig";
import {useMsal} from "@azure/msal-react";


const Login = () => {

  const { instance } = useMsal();

  function doLogin() {
    instance.loginPopup(loginRequest).then(((result) => {
      instance.setActiveAccount(result.account)
    }))
  }

  return (
    <div>
      <Grid container justifyContent={"center"} direction={"row"}>
        <Grid sx={{p: 1}} item>
          <Paper variant={"elevation"} elevation={2}>
            <Box sx={{my: 2, p: 1, textAlign: "center"}}>
              <img src={logo} style={{width: "100px"}} alt={"Essium Logo"} />
            </Box>
            <Box sx={{m: 2}}>
              <Typography>Welcome Back! Please login to your account</Typography>
            </Box>
            <Box sx={{mb: 1, p: 2, textAlign: "center"}}>
              <MicrosoftLoginButton onClick={() => doLogin()} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
