import {toast, ToastOptions} from "react-toastify";
import {ToastStatus} from "../../core/Enums";

export default function notify(msg: String, toastStatus: String) {
    const opts: ToastOptions = {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    if(toastStatus === ToastStatus.Success) {
        toast.success(msg, opts);
    } else if(toastStatus === ToastStatus.Warning) {
        toast.warn(msg, opts);
    }  else if(toastStatus === ToastStatus.Danger) {
        toast.error(msg, opts);
    } else {
        toast(msg, opts);
    }
}
