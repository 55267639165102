import React from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {Warning} from "@mui/icons-material";

const ErrorPage = () => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item>
        <Paper sx={{p: 1, textAlign: "center"}} variant={"elevation"} elevation={2}>
          <Box sx={{m: 2}}>
            <Warning fontSize={"large"} color={"error"}/>
          </Box>
          <Typography variant={"h2"} sx={{m: 2}}>
            404
          </Typography>
          <Typography sx={{m: 2}}>
            The page you are trying to view doesn't exist.
          </Typography>
          <Typography sx={{m: 2}}>
            Try the Sidebar to access Tests or Groups
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
