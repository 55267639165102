import {Action, Fab} from "react-tiny-fab";
import {Computer, DirectionsRun, Language} from "@mui/icons-material";
import React from "react";
import {startTest} from "../../data/helpers/LocalTestHelper";
import {ToastStatus} from "../../core/Enums";
import {ApiHelper as api, notify} from "../../data/helpers";
import {useMsal} from "@azure/msal-react";
import {useTheme} from "@mui/material";

interface IProps {
  testKey: string,
  updateUpcomingTestResults: (v: any[]) => void
}

const RunTestFAB = ({testKey, updateUpcomingTestResults}: IProps) => {

  // Authentication
  const {instance} = useMsal()
  const theme = useTheme();

  const fabMain = {backgroundColor: theme.palette.primary.main}
  const fabAction = {backgroundColor: theme.palette.secondary.main}

  // Calculate what Tests to expect results from
  const calculateUpcomingResults = (pieces: any[]) => {
    const endResults = [] as any;
    for (const p of pieces) {
      if (p.log_result === true && p.piece_key !== 17) {
        endResults.push({log_piece_key: '', 'incomplete': true, message: 'Pending Results...', piece: p});
      }
    }
    updateUpcomingTestResults(endResults)
  }

  // Send request to run test locally
  const executeTestLocally = () => {
    startTest(testKey, instance).then((res) => {
      if (res.all_resultable_pieces.length > 0) calculateUpcomingResults(res.all_resultable_pieces);
      if (res.all_resultable_pieces.length < 1) updateUpcomingTestResults([]);
      notify('Test Running... New Log Session Should appear soon!', ToastStatus.Success)
    }).catch(() => notify('Failed to start test!', ToastStatus.Warning));
  }

  // Send request to run test on browserstack
  const executeTestBStack = () => {
    api.executeTest(testKey, instance).then((res) => {
      if (res.all_resultable_pieces.length > 0) calculateUpcomingResults(res.all_resultable_pieces);
      if (res.all_resultable_pieces.length < 1) updateUpcomingTestResults([]);
      notify('Test Running... New Log Session Should appear soon!', ToastStatus.Success)
    }).catch(() => notify('Failed to start test!', ToastStatus.Warning));
  }

  return (
    <Fab
      mainButtonStyles={fabMain}
      icon={<DirectionsRun/>}
      event={'hover'}
    >
      <Action text='Run Locally' onClick={executeTestLocally} style={fabAction}>
        <Computer/>
      </Action>
      <Action text='Run on Browserstack' onClick={executeTestBStack} style={fabAction}>
        <Language/>
      </Action>

    </Fab>
  )
}

export default RunTestFAB;
